export default {
    name: "warrantshoutout",
    created() {
        window.shoutTabClick = this.shoutTabClick;
    },
    mounted() {
        this.getshoutoutDate();
    },
    data() {
        return {
            dataArr: [],
            limit: 10,
            limitText: 'Show All'
        };
    },
    methods: {
        ShowAll() {
            if (this.limitText === 'Show All') {
                this.limit = ''
                this.limitText = 'Show Latest'
            } else if (this.limitText === 'Show Latest') {
                this.limit = 10
                this.limitText = 'Show All'
            }
            this.getshoutoutDate()
        },
        getshoutoutDate() {
            $.ajax({
                type: "post",
                url: this.$js.webservice + 'GetDeployedAnnouncement',
                data: { token: 'webkey', limit: this.limit },
                dataType: "json",
                success: (data) => {
                    if (data.status === "SUCCESS" && data.text.length > 0) {
                        this.dataArr = data.text;
                        this.assignmentShoutout(this.dataArr);
                        this.shoutTabClick(0);
                    }
                },
                error: function (error) {
                    console.log(error)
                }
            })
        },
        assignmentShoutout(data) {
            var html = '';
            $.each(data, (index, datas) => {
                var date = this.$js.formatDate(datas.created_datetime)
                html += "<div class='row shout-tab cur-pointer' onClick='shoutTabClick(" + index + ")'>";
                html += '<div class="col-4 pr-0">';
                html += '<div class="font-size18 font-weight-bold pl-2 pb-3"><span>' + date + '</span></div>';
                html += '</div>';
                html += '<div class="col pr-2">';
                html += '<div class="font-size18 font-weight-bold shout-title Shoutoutwarrants_title"><span>' + datas.title + '</span></div>';
                html += '<div class="Shoutoutwarrants_content font_size14"><span>' + datas.overview + '</span></div>';
                html += '</div>';
                html += '</div>';
            })
            $(".shout-left-bar").empty();
            $(".shout-left-bar").append(html);
        },
        shoutTabClick(index) {
            this.$js.SetAnchor($('.shout-right-bar').offset().top)
            if (localStorage.getItem('ShoutoutwarrantsLi')) {
                index = localStorage.getItem('ShoutoutwarrantsLi')
                localStorage.removeItem('ShoutoutwarrantsLi')
            }
            //赋值
            $("#rightText").html(this.dataArr[index].title);
            $("#rightContent").html(this.dataArr[index].content);
            $(".shout-tab").eq(index).addClass("shouttab_clickbg").siblings().removeClass("shouttab_clickbg");
        }
    }
}